import * as React from 'react';
import injectSheet from 'react-jss';
import { Container } from 'reactstrap';
import { styles } from '../../../Styles';
import { Theme } from '../../../Theme';
import { Content } from '../../../api/gen/index.eotc';

import './sermon.css';
import './sermon_responsive.css';

import moment from 'moment';

  const style =  (theme: Theme) => ({
      ...styles(theme),
  });

  type SermonProps = Content & {
    classes: any,
  };

  const blog = (props: SermonProps) => {
    const { title, subTitle, text, link, style, image, video, createdBy, preacher, categories, classes} = props;
    let { date } = props;
   
        return (
            <React.Fragment>
                <div className={`col-lg-${(style && style.colSpan) || 4} sermon_col`}>
					<div className="card">
						<div className="sermon_event_date d-flex flex-column align-items-center justify-content-center">
							<div className="sermon_event_day">{moment(date).date()}</div>
							<div className="sermon_event_month">{moment(date).format('MMM')}</div>
                       </div>
						{ video ? ( video.source === "Facebook" ?
							 <iframe className="fb-iframe"
										src={`https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FDebere.Selam.Kiduss.Michael%2Fvideos%2F${video.id}%2F&amp;show_text=0&amp`}
										scrolling="no"
										>
							</iframe> : <iframe className={`yt-iframe-${(style && style.colSpan) || 4}`} src={`https://www.youtube.com/embed/${video.id}`} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" ></iframe> 
							)  :
							<img className="card-img-top" src={image} alt="" />
						}
						{ (title || preacher || categories) &&			
						<div className="card-body text-center">
							<div className="card-title sermon_title"><a href="sermon.html">{title}</a></div>
							<div className="card-text sermon_info_container">
								{ preacher && <div className="sermon_info">
									{/*<div className="sermon_info_title">Pastor: </div> */}
									<ul className="sermon_info_list">
										<li>{preacher}</li>
									</ul>
								</div>
								}
								{ categories && <div className="sermon_info">
									<div className="sermon_info_title">Categories: </div>
									<ul className="sermon_info_list">
										{
										categories.split(',').map((category: string, index: number) => <li key={"li" + index}><a href="#">{category}, </a></li>)
										}
									</ul>
								</div>
								}
							</div>
						</div>
						}
					</div>
				</div>
            </React.Fragment>
        );
  };

const StyledComponent = injectSheet(style)(blog);

export class Sermon extends React.Component<any> {
    public render() {
        return (
            <StyledComponent {...this.props} />
        );
    }
};