import * as React from 'react';
import injectSheet from 'react-jss';
import { styles } from '../../../Styles';
import { Theme } from '../../../Theme';
import { Content } from '../../../api/gen/index.eotc';

import "./list.css";

  const style =  (theme: Theme) => ({
      ...styles(theme),
      padding: {
          padding: 10,
      },
      video: {
        padding: 10,
        float: 'left',
      }
  });

  export type ContentProps = Content & {
    classes: any,
  };

  const gc = (props: ContentProps) => {
    const { items, style, classes  } = props;
        return (
            <React.Fragment>
                <div className={(style && `col-lg-${style.colSpan || 4}`) + ` list`}>
                   { items && <ul>
                    {
                        items.map(item => {
                            const index = item.indexOf('>>');
                            let subItems = null;
                            if(index > 0 ) {
                                subItems = item.substring(index + 2).split(',');
                                item = item.substring(0, index );
                            } 

                            return <li>
                                { <span dangerouslySetInnerHTML={{__html: item}} /> }
                                { subItems && <ul> { subItems.map(si => <li> {  <span dangerouslySetInnerHTML={{__html: si}} /> }</li>)} </ul>}
                            </li>
                        })
                    }
                    </ul>
                   }
                </div>
            </React.Fragment>
        );
  };

const StyledComponent = injectSheet(style)(gc);

export class List extends React.Component<any> {
    public render() {
        return (
            <StyledComponent {...this.props} />
        );
    }
};