import * as React from 'react';
import injectSheet from 'react-jss';
import { Container } from 'reactstrap';
import { styles } from '../../../Styles';
import { Theme } from '../../../Theme';
import { Content } from '../../../api/gen/index.eotc';

import './events.css';
import './events_responsive.css';

import moment from 'moment';

  const style =  (theme: Theme) => ({
      ...styles(theme),
  });

  type EventProps = Content & {
    classes: any,
  };

  const e = (props: EventProps) => {
    const { title, subTitle, text, link, image, style, video, location, classes} = props;
    let { startTime, endTime } = props;
    let days, hours, minutes, seconds;
    const start_date = Date.parse((startTime || new Date()).toString());
    const setTime = () =>
    {
        // find the amount of "seconds" between now and target
        var current_date = new Date().getTime();
        var seconds_left = (start_date - current_date) / 1000;
     
        // do some time calculations
        days = Math.ceil(seconds_left / 86400);
        seconds_left = seconds_left % 86400;
         
        hours = Math.ceil(seconds_left / 3600);
        seconds_left = seconds_left % 3600;
         
        minutes = Math.ceil(seconds_left / 60);
        seconds = Math.ceil(seconds_left % 60);
    };
    
    setTime();
    setInterval(setTime, 1000);

    const media = () => <div className="upcoming_image">
    { 
        video ? ( video.source === "Facebook" ?
            <iframe className="fb-iframe"
                        src={`https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FDebere.Selam.Kiduss.Michael%2Fvideos%2F${video.id}%2F&amp;show_text=0&amp`}
                        >
            </iframe> : <iframe  className={`yt-iframe-${(style && style.colSpan) || 6}`} src={`https://www.youtube.com/embed/${video.id}`} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" ></iframe> 
            )  :
        image && <div><img className={classes.img} src={image} /></div>
    }
    </div>;

    const info = (days: any, hours: any, minutes: any, seconds: any) => <React.Fragment>
        <div className="event_container d-flex flex-md-row flex-column align-items-start justify-content-start">
            <div className="event_date">
                <div className="d-flex flex-column align-items-center justify-content-center">
                    <div className="event_day">{moment(startTime).date()}</div>
                    <div className="event_month">{moment(startTime).format('MMM')}</div>
                </div>
            </div>
            <div className="event_content">
                <div className="event_title"><a href="#">{ title }</a></div>
                <ul className="event_row">
                    <li>
                        <div className="event_icon"><img src="static/images/calendar.png" alt="" /></div>
                        <span>{ moment(startTime).format('LT') } - {moment(endTime).format('LT') }</span>
                    </li>
                    <li>
                        <div className="event_icon"><img src="static/images/location.png" alt="" /></div>
                        <span>{ location }</span>
                    </li>
                </ul>
            </div>
        </div>
        <div className="event_text">
        { text && text.map(t => <p> { t } </p>) }
        </div>
        { days && days > 0 && hours && hours > 0 && minutes && minutes > 0 && seconds && seconds > 0 && <div className="event_timer_container ml-lg-auto">
            <ul className="event_timer">
                <li><div id="day" className="event_num">{days}</div><div className="event_ss">day</div></li>
                <li><div id="hour" className="event_num">{hours}</div><div className="event_ss">hrs</div></li>
                <li><div id="minute" className="event_num">{minutes}</div><div className="event_ss">min</div></li>
                <li><div id="second" className="event_num">{seconds}</div><div className="event_ss">sec</div></li>
            </ul>
        </div>
        }
    </React.Fragment>;

        return (
            <React.Fragment>
                <div>
                    <div>
                    <div className={`row ${classes.row}`}>
                        { 
                            style && style.imageAlign === 'right' ? <React.Fragment>
                                <div className="col-lg-6">
                                    { info (days, hours, minutes, seconds) }
                                </div>
                                <div className="col-lg-6" style={{textAlign: 'left'}}>
                                    { media() }
                                </div>
                            </React.Fragment> : <React.Fragment>
                                <div className="col-lg-6" style={{textAlign: 'left'}}>
                                    { media() }
                                </div>
                                <div className="col-lg-6" >
                                    { info (days, hours, minutes, seconds)}
                                </div>
                            </React.Fragment>
                        }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
  };

const StyledComponent = injectSheet(style)(e);

export class Event extends React.Component<any> {
    public render() {
        return (
            <StyledComponent {...this.props} />
        );
    }
};