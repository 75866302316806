import * as React from 'react';
import injectSheet from 'react-jss';
import { styles } from '../../../Styles';
import { Theme } from '../../../Theme';
import { Content } from '../../../api/gen/index.eotc';
import ReactImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import "../../../../node_modules/react-image-gallery/styles/css/image-gallery.css";

  const style =  (theme: Theme) => ({
      ...styles(theme),
      padding: {
          padding: 10,
      },
      video: {
        padding: 10,
        float: 'left',
      }
  });

  export type ContentProps = Content & {
    classes: any,
  };

  const gc = (props: ContentProps) => {
    const { images, classes  } = props;
        return (
            <React.Fragment>
                <div className={`container ${classes.wrapper}`}>
                    <ReactImageGallery items={images as unknown as ReactImageGalleryItem[]} />
                </div>
            </React.Fragment>
        );
  };

const StyledComponent = injectSheet(style)(gc);

export class Gallery extends React.Component<any> {
    public render() {
        return (
            <StyledComponent {...this.props} />
        );
    }
};