import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { ApplicationState } from '../store';
import * as EventsStore from '../store/Events';
import { Event } from '../api/gen/index.eotc';

// At runtime, Redux will merge together...
type EventProps =
  EventsStore.EventsState // ... state we've requested from the Redux store
  & typeof EventsStore.actionCreators // ... plus action creators we've requested
  & RouteComponentProps<{ startIndex: string }>; // ... plus incoming routing parameters


class EventList extends React.PureComponent<EventProps> {
  // This method is called when the component is first added to the document
  public componentDidMount() {
    this.ensureDataFetched();
  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    this.ensureDataFetched();
  }

  public render() {
    return (
      <React.Fragment>
        <h1 id="tabelLabel">Events</h1>
        <p>This component demonstrates fetching data from the server and working with URL parameters.</p>
        {this.renderEvents()}
        {this.renderPagination()}
      </React.Fragment>
    );
  }

  private ensureDataFetched() {
    const startIndex = parseInt(this.props.match.params.startIndex, 10) || 0;
    this.props.requestEvents(startIndex);
  }

  private renderEvents() {
    const { events } = this.props;
    return (
      <table className='table table-striped' aria-labelledby="tabelLabel">
        <thead>
          <tr>
            <th>Start Time</th>
            <th>End Time</th>
            <th>Title</th>
            <th>Location</th>
          </tr>
        </thead>
        <tbody>
          {events && events.map((event: Event, index: number) =>
            <tr key={"row" + index}>
              <td>{event.startTime}</td>
              <td>{event.endTime}</td>
              <td>{event.title}</td>
              <td>{event.location}</td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }

  private renderPagination() {
    const { startIndex } = this.props;
    const prevStartIndex = (startIndex || 0) - 5;
    const nextStartIndex = (startIndex || 0) + 5;

    return (
      <div className="d-flex justify-content-between">
        <Link className='btn btn-outline-secondary btn-sm' to={`/events/${prevStartIndex}`}>Previous</Link>
        {this.props.isLoading && <span>Loading...</span>}
        <Link className='btn btn-outline-secondary btn-sm' to={`/events/${nextStartIndex}`}>Next</Link>
      </div>
    );
  }
}

export default connect(
  (state: ApplicationState) => state.events, // Selects which state properties are merged into the component's props
  EventsStore.actionCreators // Selects which action creators are merged into the component's props
)(EventList as any);
