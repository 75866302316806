import * as React from 'react';
import NavMenu from './NavMenu';
import Footer from './../components/contents/footer/Footer';

export default (props: { children?: React.ReactNode }) => (
    <React.Fragment>
        <NavMenu/>
        {props.children}
        <Footer />
    </React.Fragment>
);
