import * as Events from './Events';
import * as Blogs from './Blogs';
import * as Page from './Page';
import * as Sermons from './Sermons';
import * as Config from './Config'
import * as Footer from './Footer'

// The top-level state object
export interface ApplicationState {
    events: Events.EventsState | undefined;
    blogs: Blogs.BlogsState | undefined;
    sermons: Sermons.SermonsState | undefined;
    page: Page.PagesState | undefined;
    authConfig: Config.ConfigState | undefined;
    footer: Footer.FooterState | undefined;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    events: Events.reducer,
    blogs: Blogs.reducer,
    sermons: Sermons.reducer,
    page: Page.reducer,
    authConfig: Config.reducer,
    footer: Footer.reducer,
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
