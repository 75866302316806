import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { ApplicationState } from '../store';
import * as SermonsStore from '../store/Sermons';
import { Sermon } from '../api/gen/index.eotc';

// At runtime, Redux will merge together...
type SermonProps =
  SermonsStore.SermonsState // ... state we've requested from the Redux store
  & typeof SermonsStore.actionCreators // ... plus action creators we've requested
  & RouteComponentProps<{ startIndex: string }>; // ... plus incoming routing parameters


class SermonList extends React.PureComponent<SermonProps> {
  // This method is called when the component is first added to the document
  public componentDidMount() {
    this.ensureDataFetched();
  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    this.ensureDataFetched();
  }

  public render() {
    return (
      <React.Fragment>
        <h1 id="tabelLabel">Sermons</h1>
        <p>This component demonstrates fetching data from the server and working with URL parameters.</p>
        {this.renderSermons()}
        {this.renderPagination()}
      </React.Fragment>
    );
  }

  private ensureDataFetched() {
    const startIndex = parseInt(this.props.match.params.startIndex, 10) || 0;
    this.props.requestSermons(startIndex);
  }

  private renderSermons() {
    const { sermons } = this.props;
    return (
      <table className='table table-striped' aria-labelledby="tabelLabel">
        <thead>
          <tr>
            <th>Date</th>
            <th>Title</th>
            <th>Text</th>
            <th>Preacher</th>
          </tr>
        </thead>
        <tbody>
          {sermons && sermons && sermons.map((sermon: Sermon, index: number) =>
            <tr key={"row" + index}>
              <td>{sermon.date}</td>
              <td>{sermon.title}</td>
              <td>{sermon.text}</td>
              <td>{sermon.preacher}</td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }

  private renderPagination() {
    const { startIndex } = this.props;
    const prevStartIndex = (startIndex || 0) - 5;
    const nextStartIndex = (startIndex || 0) + 5;

    return (
      <div className="d-flex justify-content-between">
        <Link className='btn btn-outline-secondary btn-sm' to={`/sermons/${prevStartIndex}`}>Previous</Link>
        {this.props.isLoading && <span>Loading...</span>}
        <Link className='btn btn-outline-secondary btn-sm' to={`/sermons/${nextStartIndex}`}>Next</Link>
      </div>
    );
  }
}

export default connect(
  (state: ApplicationState) => state.sermons, // Selects which state properties are merged into the component's props
  SermonsStore.actionCreators // Selects which action creators are merged into the component's props
)(SermonList as any);
