import * as React from 'react';
import injectSheet from 'react-jss';
import { styles } from '../../Styles';
import { Theme } from '../../Theme';
import { Content } from '../../api/gen/index.eotc';

import "./GenericContent.css"
import { List } from './list/List';

  const style =  (theme: Theme) => ({
      ...styles(theme),
      padding: {
          padding: 10,
      }
  });

  export type ContentProps = Content & {
    classes: any,
  };

  const gc = (props: ContentProps) => {
    const { title, subTitle, text, html, items, image, video, link, style, titleStyle, type, icon, classes  } = props;
        return (
            <React.Fragment>
                <div className={style && `col-lg-${style.colSpan || 12} ${classes.wrapper}`}>
                    { icon && icon.style && icon.style.textAlign === 'center' && <i className={`${icon.class} ${classes.icon} ${classes.padding}`} style={{textAlign: icon.style.textAlign}} />}
                    { title && <h2 className={classes.title} style={{color: titleStyle && titleStyle.color, fontSize: titleStyle && titleStyle.fontSize}} >
                        { icon && icon.style && icon.style.textAlign === 'left' && <i className={`${icon.class} ${classes.icon} ${classes.padding}`} style={{textAlign: icon.style.textAlign}} />}
                                <span dangerouslySetInnerHTML={{__html: title}} />
                            </h2> 
                    }
                    { subTitle && <div className={classes.subTitle}>{subTitle}</div> }
                    { 
                        video ? ( video.source === "Facebook" ?
							 <iframe className="fb-iframe"
										src={`https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FDebere.Selam.Kiduss.Michael%2Fvideos%2F${video.id}%2F&amp;show_text=0&amp`}
										>
							</iframe> : <iframe  className={`yt-iframe-${(style && style.colSpan) || 4}`} src={`https://www.youtube.com/embed/${video.id}`} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" ></iframe> 
							)  :
                            image && <div><img className={classes.img} src={image} /></div>
                    }
					{ text && text.map(t => <p dangerouslySetInnerHTML={{__html: t}} />) }
                    { html && html.map(h => <p dangerouslySetInnerHTML={{ __html: atob(h) }} />) }
                    {
                       items && <List items={items} />
                    }
                    { link &&
                        <div className={`${classes.linkContainer} link`}>
                            <a className={classes.link} href={link.href} >
                                <span>{ link.text }</span>
                            </a>
                        </div> 
                    }        
                </div>
            </React.Fragment>
        );
  };

const StyledComponent = injectSheet(style)(gc);

export class GenericContent extends React.Component<any> {
    public render() {
        return (
            <StyledComponent {...this.props} />
        );
    }
};