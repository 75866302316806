import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { Event, ContentClient } from '../api/gen/index.eotc';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface EventsState {
    isLoading: boolean;
    startIndex?: number;
    events: Event[] | undefined;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestEventsAction {
    type: 'REQUEST_EVENTS';
    startIndex: number;
}

interface ReceiveEventsAction {
    type: 'RECEIVE_EVENTS';
    startIndex: number;
    events: Event[];
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestEventsAction | ReceiveEventsAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestEvents: (startIndex: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState && appState.events && startIndex !== appState.events.startIndex) {
            new ContentClient().getEvents(startIndex)
                .then(data => {
                    dispatch({ type: 'RECEIVE_EVENTS', startIndex: startIndex, events: data });
                });

            dispatch({ type: 'REQUEST_EVENTS', startIndex: startIndex });
        }
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: EventsState = { events: undefined, isLoading: false };

export const reducer: Reducer<EventsState> = (state: EventsState | undefined, incomingAction: Action): EventsState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_EVENTS':
            return {
                startIndex: action.startIndex,
                events: state.events,
                isLoading: true
            };
        case 'RECEIVE_EVENTS':
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly
            // handle out-of-order responses.
            if (action.startIndex === state.startIndex) {
                return {
                    startIndex: action.startIndex,
                    events: action.events,
                    isLoading: false
                };
            }
            break;
    }

    return state;
};
