import * as React from 'react';
import { connect } from 'react-redux';
import * as ConfigStore from '../store/Config';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { Configuration, UserAgentApplication, AuthenticationParameters } from 'msal';
import { ApplicationState } from '../store';

type ConfigProps =
  ConfigStore.ConfigState // ... state we've requested from the Redux store
  & typeof ConfigStore.actionCreators; // ... plus incoming routing parameters

type LoginState = {
    isOpen?: boolean;
    name?: string | undefined;
    isLoggedIn: boolean;
}

declare global {
    interface Window { 
        accessToken: any; 
        access_token: any;
    }
}

class NavMenu extends React.Component<ConfigProps, LoginState> {
    public state: LoginState = {
        isOpen: false,
        isLoggedIn: false
    };

    // configuration to initialize msal
    private msalConfig: Configuration = {
        auth: {
            clientId: "937a8b9c-8df3-4f4c-8709-876371c9ec73", //This is your client ID
            authority: "https://dskmeotc.b2clogin.com/dskmeotc.onmicrosoft.com/B2C_1_signupsignin", //This is your tenant info
            validateAuthority: false,
            redirectUri: 'https://localhost:44363/',
   
        },
        cache: {
            cacheLocation: "sessionStorage",
            storeAuthStateInCookie: true
        }
    };
    
    private appConfig = {
        b2cScopes: ["https://dskmeotc.onmicrosoft.com/api/dskm.read"],
        webApi: "https://dskmeotc.azurewebsites.net/api"
    };
    
    // instantiate MSAL
    private msal = new UserAgentApplication(this.msalConfig);

    // request to signin - returns an idToken
    private loginRequest = {
        scopes: this.appConfig.b2cScopes
    };

    // request to acquire a token for resource access
    private tokenRequest = {
        scopes: this.appConfig.b2cScopes
    };

    constructor(props: any){
        super(props);
        this.msal.handleRedirectCallback((error, response) => {
            this.getToken(this.tokenRequest).then(this.updateUI);
        });
        const user: any = this.msal.getAccount();
        if(user) {
            this.state = { isLoggedIn: true, name: user.name};
            window.accessToken = user.idToken;
        }
    }

    public componentDidMount() {
        this.props.requestConfig();
      }
    
    // updates the UI post login/token acqusition
    private updateUI = () => {
        const user = this.msal.getAccount();
        this.setState( { isLoggedIn: true, name: user.name});
        window.accessToken = user.idToken;
    }
    
    //acquire a token silently
    private getToken = (tokenRequest: AuthenticationParameters): Promise<any> => {
        return this.msal.acquireTokenSilent(tokenRequest).catch((error) => {
            console.log("aquire token popup");
            // fallback to interaction when silent call fails
            return this.msal.acquireTokenPopup(tokenRequest).then( tokenResponse=> {
                console.log(tokenResponse);
            }).catch(function(error){
            console.log("Failed token acquisition", error);
        });
        });
    }
    
    // signin and acquire a token silently with POPUP flow. Fall back in case of failure with silent acquisition to popup
    private signIn = () => {
    
        const user = this.msal.getAccount();
        if(!user) {
            this.msal.loginRedirect(this.loginRequest);
            /*this.myMSALObj.loginPopup(this.loginRequest)
            .then((loginResponse) => {
                this.getToken(this.tokenRequest).then(this.updateUI);
            }).catch(function (error) {
                console.log(error);
            });*/
        }
        else {
            this.setState( { isLoggedIn: true, name: user.name});
            this.getToken(this.tokenRequest).then(this.updateUI);
        }
    }

    // signout the user
    private logout = () => {
        // Removes all sessions, need to call AAD endpoint to do full logout
        this.msal.logout();
    }

    public render() {
        const { isLoggedIn, name } = this.state;
        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3" light sticky="">
                    <Container>
                        <NavbarBrand tag={Link} to="/" style={{padding: '0 10px', minWidth: 140}}>
                        <img src="/static/images/logo.jpg" height="50px"></img> DSKM</NavbarBrand>
                        <NavbarToggler onClick={this.toggle} className="mr-2" style={{position: 'absolute', top: 10}}/>
                        <Collapse className="d-sm-inline-flex" isOpen={this.state.isOpen} navbar style={{position: 'relative'}}>
                            <ul className="navbar-nav flex-grow">
                                <NavItem>
                                    <NavLink tag={Link} onClick={this.toggle} to="/">Home</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} onClick={this.toggle} to="/events">Events</NavLink>
                                </NavItem>
                                {/*<NavItem>
                                    <NavLink tag={Link} to="/blogs">Blogs</NavLink>
                                </NavItem>*/}
                                <NavItem>
                                    <NavLink tag={Link} onClick={this.toggle} to="/sermons">Sermons</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} onClick={this.toggle} to="/mezmur">Mezmur</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} onClick={this.toggle} to="/gallery">Gallery</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} onClick={this.toggle} to="/about">About us</NavLink>
                                </NavItem>
                            </ul>
                        </Collapse>
                        <div className="logIn">
                            {/* isLoggedIn ? 
                                <div>
                                    <span>Hello, {name}</span> <a href="javascript:void(0)" onClick={this.logout}>Signout</a>
                                </div> : 
                                <div>
                                    <a href="javascript:void(0)" onClick={this.signIn}>Sign in </a> {<span style={{ padding: '0 10px'}}> or </span> <a href="javascript:void(0)" onClick={this.signIn}>Sign up </a>}
                                </div> 
                            */}
                        </div>
                    </Container>
                </Navbar>
            </header>
        );
    }

    private toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
}

export default connect(
    (state: ApplicationState) => state.authConfig, // Selects which state properties are merged into the component's props
    ConfigStore.actionCreators // Selects which action creators are merged into the component's props
  )(NavMenu as any);