import * as React from 'react';
import injectSheet from 'react-jss';
import { Container } from 'reactstrap';
import { styles } from '../../../Styles';
import { Theme } from '../../../Theme';
import { Content } from '../../../api/gen/index.eotc';

import './blog.css';
import './blog_responsive.css';

import moment from 'moment';

  const style =  (theme: Theme) => ({
      ...styles(theme),
  });

  type BlogProps = Content & {
    classes: any,
  };

  const blog = (props: BlogProps) => {
    const { title, subTitle, text, link, style, image,video, createdBy, commentCount, classes} = props;
    let { startTime } = props;
   
        return (
            <React.Fragment>
                <div className={`col-xl-4 col-lg-${(style && style.colSpan) || 4} news_post_col`}>
                    <div className="news_post">
                        <div className="news_image">
                        { 
                            video ? ( video.source === "Facebook" ?
                                <iframe className="fb-iframe"
                                            src={`https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FDebere.Selam.Kiduss.Michael%2Fvideos%2F${video.id}%2F&amp;show_text=0&amp`}
                                            >
                                </iframe> : <iframe  className={`yt-iframe-${(style && style.colSpan) || 4}`} src={`https://www.youtube.com/embed/${video.id}`} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" ></iframe> 
                                )  :
                            image && <div><img className={classes.img} src={image} /></div>
                        }
                        </div>
                        <div className="news_post_content">
                            <div className="news_post_title">
                                <a href="blog_single.html">{title}</a>
                            </div>
                            <div className="news_post_meta">
                                <ul>
                                    <li><i className="fa fa-user" aria-hidden="true"></i><a href="#">{createdBy}</a></li>
                                    <li><i className="fa fa-calendar-o" aria-hidden="true"></i><a href="#">{moment(startTime).format('DD MMM, YYYY')}</a></li>
                                    <li><i className="fa fa-comment" aria-hidden="true"></i><a href="#">{commentCount}</a></li>
                                </ul>
                            </div>
                            <div className="news_post_text">
                            {
                                text && text.map(t => <p> { t } </p>)
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
  };

const StyledComponent = injectSheet(style)(blog);

export class Blog extends React.Component<any> {
    public render() {
        return (
            <StyledComponent {...this.props} />
        );
    }
};