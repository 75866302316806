import * as React from 'react';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../../store';
import * as FooterStore from '../../../store/Footer';
import { Content, ContentGroup } from '../../../api/gen/index.eotc';
import { GenericContent } from './../../contents/GenericContent';
import { Event } from './../../contents/event/Event';
import { isNullOrUndefined } from 'util';
import { Theme } from '../../../Theme';
import { ContentGroupStyles } from '../../../Styles';
import { Container } from 'reactstrap';
import { Blog } from './../../contents/blog/Blog';
import { Sermon } from './../../contents/sermon/Sermon';
import { Gallery } from './../../contents/gallery/Gallery';
import { List } from './../../contents/list/List';

import './footer.css';

// At runtime, Redux will merge together...
type FooterProps =
  FooterStore.FooterState // ... state we've requested from the Redux store
  & typeof FooterStore.actionCreators // ... plus action creators we've requested

const style =  (theme: Theme) => ({
    ...ContentGroupStyles(theme),
});

export type ContentGroupProps = ContentGroup & {
  style?: any;
  classes: any,
};

const gc = (props: ContentGroupProps) => {
  const { classes, contents } = props;
      return (
          <React.Fragment>
              <div className={/*type === 'banner' && */classes.wrapper}>
                  <Container className={classes.container}>
                    <div className={`row ${classes.row} footer`}>
                      {
                        contents &&  contents.map((content: Content, index: number) => {
                          if(isNullOrUndefined(content.type) || content.type === 'banner') {
                            return (
                              <GenericContent key={"gc" + index} { ...content } />
                            );
                          }
                          else if(content.type === 'event') {
                            return (
                              <Event key={"event" + index} { ...content } />
                            );
                          }
                          else if(content.type === 'blog') {
                            return (
                              <Blog key={"blog" + index} { ...content } />
                            );
                          }
                          else if(content.type === 'sermon') {
                            return (
                              <Sermon key={"sermon" + index} { ...content } />
                            );
                          }
                          else if(content.type === 'gallery') {
                            return (
                              <Gallery key={"gallery" + index} { ...content } />
                            );
                          }
                          else if(content.type === 'list') {
                            return (
                              <List key={"list" + index} { ...content } />
                            );
                          }
                        })
                      }
                    </div>
                  </Container>
              </div>
          </React.Fragment>
      );
};

const StyledComponent = injectSheet(style)(gc);

class FooterComponent extends React.PureComponent<FooterProps> {
  // This method is called when the component is first added to the document
  public componentDidMount() {
    this.ensureDataFetched();
  }

  public render() {
    return (
      <React.Fragment>
        {this.renderPage()}
      </React.Fragment>
    );
  }

  private ensureDataFetched() {
    this.props.requestFooter();
  }

  private renderPage() {
    const { footer } = this.props;
           
    return (
        <React.Fragment>
        {
          footer && footer.map((cg: ContentGroup, index: number) => <StyledComponent {...cg} key={"cg" + index}/>)
        }
      </React.Fragment>
    );
  }
}

export default connect(
  (state: ApplicationState) => state.footer, // Selects which state properties are merged into the component's props
  FooterStore.actionCreators // Selects which action creators are merged into the component's props
)(FooterComponent as any);
