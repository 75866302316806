import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { AuthenticationConfigSection, ConfigClient } from '../api/gen/index.eotc';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ConfigState {
    authConfig: AuthenticationConfigSection | undefined;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestConfigAction {
    type: 'REQUEST_CONFIG';
}

interface ReceiveConfigAction {
    type: 'RECEIVE_CONFIG';
    authConfig: AuthenticationConfigSection | undefined;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestConfigAction | ReceiveConfigAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestConfig: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState && appState.authConfig) {
            new ConfigClient().getAuthConfig()
                .then(data => {
                    dispatch({ type: 'RECEIVE_CONFIG', authConfig: data });
                });

            dispatch({ type: 'REQUEST_CONFIG' });
        }
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: ConfigState = { authConfig: undefined };

export const reducer: Reducer<ConfigState> = (state: ConfigState | undefined, incomingAction: Action): ConfigState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_CONFIG':
            return {
                authConfig: state.authConfig
            };
        case 'RECEIVE_CONFIG':
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly
            // handle out-of-order responses.
            return {
                authConfig: action.authConfig
            };
    }

    return state;
};
