import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { ApplicationState } from '../store';
import * as BlogsStore from '../store/Blogs';
import { Blog } from '../api/gen/index.eotc';

// At runtime, Redux will merge together...
type BlogProps =
  BlogsStore.BlogsState // ... state we've requested from the Redux store
  & typeof BlogsStore.actionCreators // ... plus action creators we've requested
  & RouteComponentProps<{ startIndex: string }>; // ... plus incoming routing parameters


class BlogList extends React.PureComponent<BlogProps> {
  // This method is called when the component is first added to the document
  public componentDidMount() {
    this.ensureDataFetched();
  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    this.ensureDataFetched();
  }

  public render() {
    return (
      <React.Fragment>
        <h1 id="tabelLabel">Blogs</h1>
        <p>This component demonstrates fetching data from the server and working with URL parameters.</p>
        {this.renderBlogs()}
        {this.renderPagination()}
      </React.Fragment>
    );
  }

  private ensureDataFetched() {
    const startIndex = parseInt(this.props.match.params.startIndex, 10) || 0;
    this.props.requestBlogs(startIndex);
  }

  private renderBlogs() {
    const { blogs } = this.props;
    return (
      <table className='table table-striped' aria-labelledby="tabelLabel">
        <thead>
          <tr>
            <th>Date</th>
            <th>Created By</th>
            <th>Title</th>
            <th>Image</th>
          </tr>
        </thead>
        <tbody>
          {blogs && blogs && blogs.map((blog: Blog, index: number) =>
            <tr key={"row" + index}>
              <td>{blog.date}</td>
              <td>{blog.createdBy}</td>
              <td>{blog.title}</td>
              <td>{blog.image}</td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }

  private renderPagination() {
    const { startIndex } = this.props;
    const prevStartIndex = (startIndex || 0) - 5;
    const nextStartIndex = (startIndex || 0) + 5;

    return (
      <div className="d-flex justify-content-between">
        <Link className='btn btn-outline-secondary btn-sm' to={`/blogs/${prevStartIndex}`}>Previous</Link>
        {this.props.isLoading && <span>Loading...</span>}
        <Link className='btn btn-outline-secondary btn-sm' to={`/blogs/${nextStartIndex}`}>Next</Link>
      </div>
    );
  }
}

export default connect(
  (state: ApplicationState) => state.blogs, // Selects which state properties are merged into the component's props
  BlogsStore.actionCreators // Selects which action creators are merged into the component's props
)(BlogList as any);
