import * as React from 'react';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import * as PageStore from '../store/Page';
import { Content, ContentGroup } from '../api/gen/index.eotc';
import { GenericContent } from './contents/GenericContent';
import { Event } from './contents/event/Event';
import { isNullOrUndefined } from 'util';
import { Theme } from '../Theme';
import { ContentGroupStyles } from '../Styles';
import { Container } from 'reactstrap';
import { Blog } from './contents/blog/Blog';
import { Sermon } from './contents/sermon/Sermon';
import { Gallery } from './contents/gallery/Gallery';
import { List } from './contents/list/List';
import copy from 'copy-to-clipboard';
import Modal from 'react-modal';
 
import './page.css';

// At runtime, Redux will merge together...
type PageProps =
  PageStore.PagesState // ... state we've requested from the Redux store
  & typeof PageStore.actionCreators // ... plus action creators we've requested
  & RouteComponentProps<{ path: string }>; // ... plus incoming routing parameters

const style =  (theme: Theme) => ({
    ...ContentGroupStyles(theme),
});

export type ContentGroupProps = ContentGroup & {
  style?: any;
  classes: any,
};

const gc = (props: ContentGroupProps) => {
  const { classes, contents, path } = props;
  const shareUrl =  path && window.location.hostname + '/' + path.replace(/\//g, '-').replace('.json', '').substring(1);
  const showShare = contents && contents.length > 0 && (contents.find(c => c.text !== null) || contents.find(c => c.items !== null) || contents.find(c => c.image !== null));
  console.log(showShare);
  let show = false;

      return (
          <React.Fragment>
              <div className={/*type === 'banner' && */classes.wrapper}>
                  <Container className={classes.container}>
                    <div className={`row ${classes.row}`} id={path && path.replace(/\//g, '-').replace('.json','').substring(1)} style={{position: 'relative'}}>
                      { shareUrl && showShare && <span onClick={() => { copy(shareUrl); show = true; setTimeout(() => {
                        show = false;
                      }, 1000); } } style={{cursor: 'pointer', position: 'absolute', right: 10, top: -10, fontSize: 24}} className="fa fa-share-alt" ></span> }
                      { show && <Modal isOpen={show} contentLabel="Copied to clipboard" /> }
                      {
                        contents &&  contents.map((content: Content, index: number) => {
                          if(isNullOrUndefined(content.type) || content.type === 'banner') {
                            return (
                              <GenericContent key={"gc" + index} { ...content } />
                            );
                          }
                          else if(content.type === 'event') {
                            return (
                              <Event key={"event" + index} { ...content } />
                            );
                          }
                          else if(content.type === 'blog') {
                            return (
                              <Blog key={"blog" + index} { ...content } />
                            );
                          }
                          else if(content.type === 'sermon') {
                            return (
                              <Sermon key={"sermon" + index} { ...content } />
                            );
                          }
                          else if(content.type === 'gallery') {
                            return (
                              <Gallery key={"gallery" + index} { ...content } />
                            );
                          }
                          else if(content.type === 'list') {
                            return (
                              <List key={"list" + index} { ...content } />
                            );
                          }
                        })
                      }
                    </div>
                  </Container>
              </div>
          </React.Fragment>
      );
};

const StyledComponent = injectSheet(style)(gc);

class PageComponent extends React.PureComponent<PageProps> {
  // This method is called when the component is first added to the document
  public componentDidMount() {
    this.ensureDataFetched();
  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    this.ensureDataFetched();
  }

  public render() {
    return (
      <React.Fragment>
        {this.renderPage()}
      </React.Fragment>
    );
  }

  private ensureDataFetched() {
    const path = this.props.match.params.path || "home";
    this.props.requestPage(path);
  }

  private renderPage() {
    const { path } = this.props;
    const page = this.props.pages && this.props.pages.find(p => p.path === path);
           
    return (
        <React.Fragment>
        {
          page && page.contents && page.contents.map((cg: ContentGroup, index: number) => <StyledComponent {...cg} key={"cg" + index}/>)
        }
      </React.Fragment>
    );
  }
}

export default connect(
  (state: ApplicationState) => state.page, // Selects which state properties are merged into the component's props
  PageStore.actionCreators // Selects which action creators are merged into the component's props
)(PageComponent as any);
