import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { ContentClient, Page } from '../api/gen/index.eotc';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface PagesState {
    isLoading: boolean;
    path?: string;
    pages: Page[] | undefined;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestPageAction {
    type: 'REQUEST_PAGE';
    path: string;
}

interface ReceivePageAction {
    type: 'RECEIVE_PAGE';
    path: string;
    page?: Page | undefined;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestPageAction | ReceivePageAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestPage: (path: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState && appState.page && path !== appState.page.path) {
            if((!appState.page.pages || !appState.page.pages.find(p => p.path === path))) {
                dispatch({ type: 'REQUEST_PAGE', path: path });
                new ContentClient().getPage(path)
                .then(data => {
                    dispatch({ type: 'RECEIVE_PAGE', path: path, page: data });
                });
            }
            else{
                dispatch({ type: 'REQUEST_PAGE', path: path });
                dispatch({ type: 'RECEIVE_PAGE', path: path });
            }
        } 
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: PagesState = { pages: undefined, isLoading: false };

export const reducer: Reducer<PagesState> = (state: PagesState | undefined, incomingAction: Action): PagesState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_PAGE':
        return {
            path: action.path,
            pages: state.pages,
            isLoading: true
        };
        case 'RECEIVE_PAGE':
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly
            // handle out-of-order responses.
            if (action.path === state.path) {
                const pages = state.pages;
                if(!pages) {
                    return {
                        path: action.path,
                        pages: action.page ? [ action.page ] : [],
                        isLoading: false
                    };    
                } else {
                    const p = pages.find(p => p.path === action.path);
                    if(p) {
                        p.contents = action.page ? action.page.contents : p.contents;
                        return {
                            path: action.path,
                            pages: state.pages,
                            isLoading: false
                        };
                    } else {
                        action.page && pages.push(action.page);
                        return {
                            path: action.path,
                            pages: state.pages,
                            isLoading: false
                        };
                    }
                }
            }
            break;
    }
    return state;
};
