import * as React from 'react';
import { Route } from 'react-router';
import Layout from './components/Layout';
import Home from './components/Home';

import './custom.css'
import EventList from './components/EventList';
import BlogList from './components/BlogList';
import SermonList from './components/SermonList';
import Page from './components/Page';

export default () => (
    <Layout>
        <Route exact path='/' component={Page} />
        <Route path='/events/list/:startIndex?' component={EventList} />
        <Route path='/blogs/list/:startIndex?' component={BlogList} />
        <Route path='/sermons/list/:startIndex?' component={SermonList} />
        <Route path='/:path' component={Page} />
    </Layout>
);
